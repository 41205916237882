import React, { useEffect, useState } from 'react';

function Typewriter() {
    const [username, setUsername] = useState('');
    const [showCursor, setShowCursor] = useState(true);
    const text = "Yogesh Aryal"; // Replace "YourUsername" with the desired username
    const speed = 100; // Adjust the speed (in milliseconds) of the typing effect
    const repeatDelay = 5000; // Repeat the animation every 5 seconds

    useEffect(() => {
        let i = 0;
        let timer;

        const typeNextChar = () => {
            if (i < text.length) {
                setUsername(prevUsername => prevUsername + text.charAt(i));
                i++;
                timer = setTimeout(typeNextChar, speed);
            } else {
                // Reset and repeat after repeatDelay
                setShowCursor(prevShowCursor => !prevShowCursor)
                setTimeout(() => {
                    setUsername('');
                    setShowCursor(prevShowCursor => !prevShowCursor)
                    i = 0;
                    typeNextChar(); // Start the typing animation again
                }, repeatDelay);

            }
        };

        typeNextChar();

        // Clear timeout on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <a href="/" className="logo">
            <span className="grey-color">&lt;</span>
            <span className="logo-name">{username}</span>
            {showCursor && <span className="cursor grey-color">|</span>} {/* Cursor */}
            <span className="grey-color">/&gt;</span>
        </a>
    );
}

export default Typewriter;
